





























































































































































































































import Base from '@/mixins/Base.vue';
import { IFlowsResponse, IFlow } from '@/interfaces/flow';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({

    data() {
        return {
            params: {
                active: null,
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
            },

            flows: {
                data: [] as Array<IFlow>,
                meta: {} as IPagination | any,
            } as IFlowsResponse,
        };
    },
    mounted() {
        this.getFlows();
    },
    methods: {
        getFlows(page: number = 1): void {
            this.params.page = page;

            this.get<IFlowsResponse>('flows', {
                params: {
                    ...this.params,
                },
            }).then(({ data }) => {
                this.flows = data;
            });
        },
    },
});

export default component;
